import React, { useEffect, useState, useRef, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { getFirestore, doc, getDoc, updateDoc, arrayUnion, arrayRemove, setDoc } from 'firebase/firestore';
import { getStorage, ref, getDownloadURL } from 'firebase/storage';
import './MobileVideoPage.css';
import { formatDistanceToNow } from 'date-fns';
import { FaHeart, FaRegHeart, FaRegComment, FaRegPaperPlane, FaRegBookmark, FaBookmark } from 'react-icons/fa';
import { getAuth } from 'firebase/auth';

const MobileVideoPage = () => {
    const { videoId } = useParams();
    const [video, setVideo] = useState(null);
    const [videoURL, setVideoURL] = useState("");
    const [likes, setLikes] = useState(0);
    const [isLiked, setIsLiked] = useState(false);
    const [isFavorited, setIsFavorited] = useState(false);
    const [comments, setComments] = useState([]);
    const videoRef = useRef(null);

    const checkIfFavorited = useCallback(async (userId) => {
        const db = getFirestore();
        const userDocRef = doc(db, 'users', userId);

        try {
            const userDoc = await getDoc(userDocRef);
            if (userDoc.exists()) {
                const data = userDoc.data();
                if (data.favorites && data.favorites.includes(videoId)) {
                    setIsFavorited(true);
                }
            }
        } catch (error) {
            console.error('Error checking if favorited:', error);
        }
    }, [videoId]);

    const ensureVideoFields = async (videoDocRef, videoData) => {
        const updates = {};
        // Check each field and set default values if missing
        if (typeof videoData.likes !== 'number') updates.likes = 0; // Ensure 'likes' is a number
        if (!Array.isArray(videoData.likedBy)) updates.likedBy = []; // Ensure 'likedBy' is an array
        if (!Array.isArray(videoData.comments)) updates.comments = []; // Ensure 'comments' is an array

        if (Object.keys(updates).length > 0) {
            try {
                console.log('Ensuring video fields, current data:', videoData);
                // Update the video document with missing fields
                await updateDoc(videoDocRef, updates);
                console.log('Added missing fields to the video document:', updates);
                // Merge the missing fields with the current state
                setVideo((prev) => ({ ...prev, ...updates }));
            } catch (error) {
                console.error('Error ensuring video fields:', error);
            }
        }
    };

    const createVideoDocument = async (videoDocRef) => {
        try {
            // Define the default structure of a video document
            const defaultData = {
                comments: [],
                likedBy: [],
                likes: 0,
                createdAt: new Date(),
                views: 0,
                thumbnailURL: '',
                videoURL: '',
            };

            // Create the document in Firestore with the default data
            await setDoc(videoDocRef, defaultData);
            console.log('Created new document for video:', videoDocRef.id);

            // Set state with the default data
            setVideo(defaultData);
            setLikes(0);
            setComments([]);

            // Fetch and set the video URL immediately after creation
            await fetchVideoURL(videoDocRef.id);
        } catch (error) {
            console.error('Error creating video document:', error);
        }
    };

    const fetchVideoURL = async (videoId) => {
        try {
            const storage = getStorage();
            const videoStorageRef = ref(storage, `videos/${videoId}`);
            const url = await getDownloadURL(videoStorageRef);
            setVideoURL(url);
            console.log('Fetched video URL:', url);
        } catch (error) {
            console.error('Error fetching video URL:', error);
        }
    };

    useEffect(() => {
        const fetchVideo = async () => {
            const db = getFirestore();
            const videoDocRef = doc(db, 'videos', videoId);

            try {
                console.log('Fetching video data for video ID:', videoId);
                const videoDoc = await getDoc(videoDocRef);

                // Check if document exists or if data is undefined
                if (!videoDoc.exists() || !videoDoc.data()) {
                    console.log('No such video document found, creating a new one...');
                    await createVideoDocument(videoDocRef);
                } else {
                    const videoData = videoDoc.data();
                    console.log('Fetched video data:', videoData);

                    // Ensure the video document has all necessary fields
                    await ensureVideoFields(videoDocRef, videoData);

                    // Set the video state with fallbacks for missing properties
                    setVideo({
                        ...videoData,
                        likes: typeof videoData.likes === 'number' ? videoData.likes : 0,
                        likedBy: Array.isArray(videoData.likedBy) ? videoData.likedBy : [],
                        comments: Array.isArray(videoData.comments) ? videoData.comments : [],
                    });
                    setLikes(typeof videoData.likes === 'number' ? videoData.likes : 0);
                    setComments(Array.isArray(videoData.comments) ? videoData.comments : []);

                    const auth = getAuth();
                    if (auth.currentUser) {
                        const isUserLiked = Array.isArray(videoData.likedBy) && videoData.likedBy.includes(auth.currentUser.uid);
                        setIsLiked(isUserLiked);
                        checkIfFavorited(auth.currentUser.uid);
                    }

                    // Fetch and set the video URL
                    await fetchVideoURL(videoId);
                }
            } catch (error) {
                console.error('Error fetching video:', error);
            }
        };

        fetchVideo();
    }, [videoId, checkIfFavorited]);

    useEffect(() => {
        if (videoRef.current && videoURL) {
            videoRef.current.play();
        }
    }, [videoURL]);

    const toggleLike = async () => {
        const auth = getAuth();
        if (!auth.currentUser) {
            console.log('User not logged in. Cannot like the video.');
            return;
        }

        const db = getFirestore();
        const videoDocRef = doc(db, 'videos', videoId);

        try {
            if (isLiked) {
                await updateDoc(videoDocRef, {
                    likes: likes - 1,
                    likedBy: arrayRemove(auth.currentUser.uid),
                });
                setLikes(likes - 1);
                setIsLiked(false);
            } else {
                await updateDoc(videoDocRef, {
                    likes: likes + 1,
                    likedBy: arrayUnion(auth.currentUser.uid),
                });
                setLikes(likes + 1);
                setIsLiked(true);
            }
        } catch (error) {
            console.error('Error updating like:', error);
        }
    };

    const toggleFavorite = async () => {
        const auth = getAuth();
        if (!auth.currentUser) {
            console.log('User not logged in. Cannot favorite the video.');
            return;
        }

        const db = getFirestore();
        const userDocRef = doc(db, 'users', auth.currentUser.uid);

        try {
            const userDoc = await getDoc(userDocRef);
            if (userDoc.exists()) {
                if (isFavorited) {
                    await updateDoc(userDocRef, {
                        favorites: arrayRemove(videoId),
                    });
                    setIsFavorited(false);
                } else {
                    await updateDoc(userDocRef, {
                        favorites: arrayUnion(videoId),
                    });
                    setIsFavorited(true);
                }
            } else {
                await setDoc(userDocRef, {
                    favorites: [videoId],
                });
                setIsFavorited(true);
            }
        } catch (error) {
            console.error('Error updating favorite:', error);
        }
    };

    if (!video) {
        return <div>Loading...</div>;
    }

    const videoCreatedDate = video.created?.seconds ? new Date(video.created.seconds * 1000) : new Date();
    const usernameInitial = video.username?.charAt(0).toUpperCase() || 'A';

    return (
        <div className="mobile-video-page">
            <div className="video-header">
                <div className="user-info">
                    <div className="profile-image-circle">
                        {usernameInitial}
                    </div>
                    <div className="user-details">
                        <span className="username">{video.username || 'Anonymous'}</span>
                    </div>
                </div>
            </div>
            <div className="video-container">
                <video ref={videoRef} src={videoURL} controls playsInline />
            </div>
            <div className="video-interactions">
                <div className="icons-container">
                    {isLiked ? (
                        <FaHeart className="icon liked" onClick={toggleLike} />
                    ) : (
                        <FaRegHeart className="icon" onClick={toggleLike} />
                    )}
                    <FaRegComment className="icon" />
                    <FaRegPaperPlane className="icon" />
                    {isFavorited ? (
                        <FaBookmark className="icon favorited" onClick={toggleFavorite} />
                    ) : (
                        <FaRegBookmark className="icon" onClick={toggleFavorite} />
                    )}
                </div>
                <div className="likes">
                    <span>{likes} likes</span>
                </div>
                <div className="comments">
                    <span>View all {comments.length} comments</span>
                </div>
                <div className="video-date">
                    <span>{formatDistanceToNow(videoCreatedDate)} ago</span>
                </div>
            </div>
        </div>
    );
};

export default MobileVideoPage;
